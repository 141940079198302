<template>
  <div class="layout employer-layout">
    <employer-header />
    <employer-mobile-menu v-if="showMobileMenu"></employer-mobile-menu>

    <div class="layout-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import EmployerHeader from '@/components/employer/EmployerHeader.vue';
import EmployerMobileMenu from '@/components/employer/EmployerMobileMenu.vue';

export default {
  name: 'EmployerLayout',
  components: {
    EmployerHeader,
    EmployerMobileMenu,
  },
  computed: {
    showMobileMenu() {
      return this.$store.state.mobileMenuStatus;
    },
  },
};
</script>
